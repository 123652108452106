<template>
    <div class="full-page" title="">
        <div class="card bs-4 w-30r">
            <h2 class="text-primary">Login</h2>
            <p class="lead">Login with your credentials</p>
            <form @submit.prevent="loginClicked">
                <validated-input icon="fa fa-user" label="Email" v-model="model.email"
                                 :disabled="loading" :rules="rules.email"/>
                <validated-input icon="fa fa-lock" label="Password" type="password" v-model="model.password"
                                 :disabled="loading" :rules="rules.password"/>
                <span class="text-danger font-weight-bold" v-if="errorMessage" v-html="errorMessage"></span>
                <btn icon="fa fa-lock" text="Login" loading-text="Validating Credentials..." size="block"
                     :disabled="loading" :loading="loading">
                </btn>
            </form>
        </div>
    </div>
</template>

<script>
import urls from '@/data/urls';
import { mapActions } from 'vuex';
import axios from 'secure-axios';

export default {
    name : 'Login',
    data () {
        return {
            loading      : false,
            loginUrl     : urls.auth.login,
            errorMessage : '',
            model        : {
                email    : '',
                password : ''
            },
            rules : {
                email : {
                    required : true
                },
                password : {
                    required : true
                }
            }
        };
    },
    methods : {
        ...mapActions(['setUser']),
        async loginClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.auth.login, that.model);
            const json = response.data;
            console.log(json);
            if (json.name) {
                const redirectUrl = sessionStorage.getItem('redirectPath');
                that.setUser(json);
                that.$router.push(redirectUrl || { path : '/' });
                that.loading = false;
            } else if (json.error === true) {
                that.errorMessage = 'Invalid Credentials';
                that.loading = false;
            } else {
                that.errorMessage = 'Invalid Credentials';
                that.loading = false;
            }
            that.loading = false;
            localStorage.clear();
        }
    }
};
</script>

<style>
.full-page {
    height: 100vh;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}
</style>
